.ingredient-list {
  width: 30rem;
  max-width: 80%;
  margin: auto;
}

.ingredient-list h2 {
  border-bottom: 3px solid #ccc;
  padding-bottom: 1rem;
}

.ingredient-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ingredient-list li {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: space-between;
}

.user-initial {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size:14px;
  color:grey;
  border: 1px solid #ccc;
  text-align:center;
  opacity:0.8;
  float:right;
  
  
  
}

.created {
  font-size: 12px;
  font-style: italic;
  vertical-align: middle;
  margin-top:3px;
  color:rgb(172, 172, 172);
  margin-right: 5px;
}

.tag {
  display: flex;
  float:right;
  text-align:right;
  justify-content: center;
  align-items: center; 
  
  
  
}
