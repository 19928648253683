@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
}

.button-primary {
  font: inherit;
  background: #ff2058;
  padding: 0.5rem 2rem;
  color: white;
  border: 1px solid #ff2058;
  margin: 0.5rem 0;
  border-radius: 5px;
  cursor: pointer;
}

button:hover,
button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}

button:focus {
  outline: none;
}

.button-white {
  font: inherit;
  background: white;
  padding: 0.5rem 2rem;
  color: #ff2058;
  border: 1px solid #ff2058;
  margin: 0.5rem 0;
  border-radius: 5px;
  cursor: pointer;
}

.avatar {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right:10px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 44px;
  height: 44px;
  margin: 6px;
  border: 6px solid #ff2058;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff2058 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card {
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.ingredient-form {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
}

.form-control label,
.form-control input {
  display: block;
  width: 100%;
}

.form-control input {
  font: inherit;
  padding: 0.1rem 0.25rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
}

.form-control input:focus {
  outline: none;
  border-bottom-color: #ff2058;
}

.ingredient-form__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ingredient-list {
  width: 30rem;
  max-width: 80%;
  margin: auto;
}

.ingredient-list h2 {
  border-bottom: 3px solid #ccc;
  padding-bottom: 1rem;
}

.ingredient-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ingredient-list li {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: space-between;
}

.user-initial {
  vertical-align: middle;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size:14px;
  color:grey;
  border: 1px solid #ccc;
  text-align:center;
  opacity:0.8;
  float:right;
  
  
  
}

.created {
  font-size: 12px;
  font-style: italic;
  vertical-align: middle;
  margin-top:3px;
  color:rgb(172, 172, 172);
  margin-right: 5px;
}

.tag {
  display: flex;
  float:right;
  text-align:right;
  justify-content: center;
  align-items: center; 
  
  
  
}

.error-modal {
  position: fixed;
  top: 30vh;
  left: calc(50% - 15rem);
  width: 30rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 100;
  border-radius: 7px;
}

.error-modal h2 {
  margin: 0;
  padding: 1rem;
  background: #ff2058;
  color: white;
  border-radius: 7px 7px 0 0;
}

.error-modal p {
    padding: 1rem;
}

.error-modal__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 0.5rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 50;
}

.search {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
}

.search-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.search-input input {
  font: inherit;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.15rem 0.25rem;
}

.search-input input:focus {
  outline: none;
  border-color: #ff2058;
}

@media (min-width: 768px) {
  .search-input {
    flex-direction: row;
  }
}

