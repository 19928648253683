.ingredient-form {
  width: 30rem;
  margin: 2rem auto;
  max-width: 80%;
}

.form-control label,
.form-control input {
  display: block;
  width: 100%;
}

.form-control input {
  font: inherit;
  padding: 0.1rem 0.25rem;
  border: none;
  border-bottom: 2px solid #ccc;
  margin-bottom: 1rem;
}

.form-control input:focus {
  outline: none;
  border-bottom-color: #ff2058;
}

.ingredient-form__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
